import Vue from "vue";
import UserService from "@/services/UserService";

import { USER_REQUEST, USER_ERROR, USER_SUCCESS } from "../actions/user";
import { AUTH_LOGOUT } from "../actions/auth";

// Default state values
const state = {
    status: "",
    profile: {
        firstname: ''
    }
};

const getters = {
    getProfile: state => state.profile,
    isProfileLoaded: state => !!state.profile
};

const actions = {
    [USER_REQUEST]: ({ commit, dispatch }) => {
        commit(USER_REQUEST);
        UserService.getInfo()
            .then(resp => {
                if (resp.data.success == false) {
                    commit(USER_ERROR);
                    dispatch(AUTH_LOGOUT);
                }
                commit(USER_SUCCESS, resp);
            })
            .catch(() => {
                commit(USER_ERROR);
                dispatch(AUTH_LOGOUT);
            });
    }
};

const mutations = {
    [USER_REQUEST]: state => {
        state.status = "loading";
    },
    [USER_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "profile", resp.data);
    },
    [USER_ERROR]: state => {
        state.status = "error";
    },
    [AUTH_LOGOUT]: state => {
        state.profile = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};