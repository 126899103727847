import Axios from 'axios';
import { apiUrl } from "@/globals"

export default {
    getInfo() {
        return Axios({
            method: 'GET',
            baseURL: apiUrl,
            url: 'user/me',
        });
    }
}