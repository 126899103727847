import Vue from 'vue'
import App from './App.vue'


Vue.config.productionTip = false

// Import styles
import './assets/styles/index.css';

// Import FontAwsome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Import Bootstrap
import 'bootstrap'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.min.css'
Vue.use(BootstrapVue); // Make BootstrapVue available throughout your project
Vue.use(IconsPlugin); // Optionally install the BootstrapVue icon components plugin

import router from '@/router'
import store from '@/store'

import { sync } from 'vuex-router-sync'
sync(store, router);

import Axios from 'axios'
const token = localStorage.getItem('user-token')
if (token) {
    Axios.defaults.headers.common['Authorization'] = token;
}

// Register inline-svg
// Vue.component('layer-inline-svg', require('./components/InlineSvg.js').default);

var vm = new Vue({
    router,
    store,
    // nprogress,
    render: h => h(App),
}).$mount('#app')

global.vm = vm;