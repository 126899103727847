import Axios from 'axios';
import { apiUrl } from "@/globals"
import router from '@/router'
import store from '@/store'
import { AUTH_LOGOUT } from "@/store/actions/auth";

export default {
    login(user) {
        return Axios({
            method: 'POST',
            baseURL: apiUrl,
            url: 'login',
            data: user,
        });
    },
};

function checkAuthStatus(response) {
    if (typeof response.data.auth_success != "undefined") {
        if (response.data.auth_success == false) {
            console.log('Performing logout from LiveDataService.js')
            store.dispatch(AUTH_LOGOUT).then(() => {
                router.push("/login");
            });
        }
    }
    return response;
}
export { checkAuthStatus };
