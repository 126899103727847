/**
 * Use this file to register any variables or functions that should be available globally
 * ideally you should make it available via the window object
 * as well as the Vue prototype for access throughout the app
 * (register globals with care, only when it makes since to be accessible app wide)
 */

// import Vue from 'vue'
// // import App from './App.vue'



// if (process.env.NODE_ENV === 'production') {
//     Vue.prototype.$apiUrl = 'https://emonitor.virtualservices.sk/api/v2/';
// } else {
//     Vue.prototype.$apiUrl = 'http://' + window.location.hostname + ':3001';
// }

let apiUrl;

if (process.env.NODE_ENV === 'production') {
    apiUrl = 'https://emonitor.virtualservices.sk/api/v2/';
} else {
    apiUrl = 'http://' + window.location.hostname + ':3001';
}

export { apiUrl };