import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'

import store from '../store' // your vuex store
import LiveDataService from "@/services/LiveDataService.js";

Vue.use(Router)

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    linkExactActiveClass: 'active',
    routes: routes
})

router.beforeEach(async (to, from, next) => {
    console.log('Before each route');
    let checkTokenResponse = await LiveDataService.checkToken();
    // router.beforeEach(async (to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    // const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    // If a route with a title was found, set the document (page) title to that value.
    // if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // const authed = localStorage.getItem("user-token");
    const authed = store.getters.isAuthenticated;
    if (to.meta.requiresAuth && !authed && checkTokenResponse.data.success !== true) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        console.log('[router] You have to authenticate to access' + to.fullPath);
        next({
            path: '/login',
            query: {
                nextUrl: to.fullPath,
            },
        });
        return;
    } else {
        console.log('[router] You have access to ' + to.fullPath);
        next();
        return;
    }
});

export default router;