import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT
} from "../actions/auth";

import Axios from 'axios';

import { USER_REQUEST } from "../actions/user";
import AuthService from "@/services/AuthService";

const state = {
    token: localStorage.getItem("user-token") || "",
    status: "", // status: loading, success, error
    // hasLoadedOnce: false
};

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status
};

const actions = {
    [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
        return new Promise((resolve, reject) => {
            // console.log(user);
            AuthService.login(user)
                .then(response => {
                    if (response.status == 200) {
                        const token = response.data.token;
                        localStorage.setItem("user-token", token);
                        // state.token = token;
                        Axios.defaults.headers.common['Authorization'] = token;
                        commit(AUTH_SUCCESS, token);
                        dispatch(USER_REQUEST);
                        resolve(response);
                    } else {
                        localStorage.removeItem("user-token");
                        reject(response);
                    }
                })
                .catch(error => {
                    commit(AUTH_ERROR, error);
                    localStorage.removeItem("user-token");
                    reject(error);
                });
        })
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise(resolve => {
            commit(AUTH_LOGOUT);
            localStorage.removeItem("user-token");
            localStorage.removeItem("rightContentHeight");
            localStorage.removeItem("layerCardHeight");
            delete Axios.defaults.headers.common['Authorization'];
            resolve();
        });
    }
};

const mutations = {
    [AUTH_REQUEST]: state => {
        state.status = "loading";
    },
    [AUTH_SUCCESS]: (state, token) => {
        state.status = "success";
        state.token = token;
        // state.hasLoadedOnce = true;
    },
    [AUTH_ERROR]: (state) => {
        state.status = "error";
        // state.hasLoadedOnce = true;
    },
    [AUTH_LOGOUT]: (state) => {
        state.token = "";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};