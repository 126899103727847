<template>
  <router-view />
</template>

<script>
import Axios from "axios";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { USER_REQUEST } from "@/store/actions/user";

export default {
  name: "app",
  created: function () {
    // intercept request to check for authorization
    Axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          // if you ever get an unauthorized, logout the user
          console.log("Dispatched AUTH_LOGOUT from App.vue");
          this.$store.dispatch(AUTH_LOGOUT);
        }
        throw err;
      });
    });
    if (this.$store.getters.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
    }
  },
};
</script>

<style>
:root {
  --scada-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* --scada-font-size-base:  */
  --scada-body-color: rgba(44, 56, 74, 0.95);
  /* --scada-body-bg:  */
}

*,
::after,
::before {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: var(--cui-font-sans-serif);
  font-size: var(--cui-font-size-base, 1rem);
  font-weight: 400;
  color: var(--cui-body-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-body-bg, #fff);
}
</style>