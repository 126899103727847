const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/layouts/ScadaLayout.vue'),
        // meta: {
        //     title: 'E-Monitor',
        //     requiresAuth: true,
        // },
        redirect: '/main-dashboard',
        children: [
            {
                path: '/main-dashboard',
                name: 'main-dashboard',
                component: () => import('@/views/Dashboard.vue'),
                meta: {
                    title: 'E-Monitor | Main Dashboard',
                    requiresAuth: true,
                }
            },
            {
                path: '/location-dashboard/location/:locationID',
                name: 'location-dashboard',
                component: () => import('@/views/Dashboard.vue'),
                props: true,
                meta: {
                    title: 'E-Monitor | Location Dashboard',
                    requiresAuth: true,
                }
            },
            {
                path: '/layers',
                name: 'layers',
                component: {
                    render(h) {
                        return h('router-view');
                    },
                },
                // redirect: '/layers/'
                children: [
                    {
                        path: '/layers/location/:locationID/layer/:layerID',
                        name: 'layer',
                        component: () => import('@/views/Layers.vue'),
                        props: true,
                        meta: {
                            title: 'E-Monitor | Layers',
                            requiresAuth: true,
                        },
                    },
                ],
            },
            {
                path: '/export/location/:locationID',
                name: 'export',
                component: () => import('@/views/Export.vue'),
                props: true,
                meta: {
                    title: 'E-Monitor | Export',
                    requiresAuth: true,
                }
            },
            {
                path: '/notifications',
                name: 'notifications',
                component: {
                    render(h) {
                        return h('router-view');
                    },
                },
                // redirect: '/layers/'
                children: [
                    {
                        path: '/notifications/log/location/:locationID',
                        name: 'notifications-log',
                        component: () => import('@/views/Notifications.vue'),
                        props: true,
                        meta: {
                            title: 'E-Monitor | Notifications Log',
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/notifications/settings/location/:locationID',
                        name: 'notifications-settings',
                        component: () => import('@/views/NotificationsSettings.vue'),
                        props: true,
                        meta: {
                            title: 'E-Monitor | Notifications Settings',
                            requiresAuth: true,
                        },
                    },
                ],
            },
            {
                path: '/documentation',
                name: "documentation",

                meta: {
                    title: 'E-Monitor | Documentation',
                    requiresAuth: true
                },

                beforeEnter() {
                    // location.href = 'https://wiki.virtualservices.sk/doku.php?id=emonitor:content'
                    window.open("https://wiki.virtualservices.sk/doku.php?id=emonitor:content", "_blank");
                    // window.open("https://wiki.maketechnology.sk/doku.php?id=emonitor:content", "_blank");
                }
                // children: [
                //     {
                //         patch: '/documentation',
                //         component: DocumentationPopup,
                //     }
                // ]
            },

        ]
    },
    {
        path: "/login",
        name: "login",
        component: () => import('@/views/Login.vue'),
        visible: 'no',
        meta: {
            title: 'E-Monitor | Login',
            requiresAuth: false,
        }
    },
]


export default routes;