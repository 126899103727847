import Axios from 'axios';
import { checkAuthStatus } from '@/services/AuthService';
import { apiUrl } from "@/globals"

export default {

    checkToken() {
        return Axios({
            method: 'POST',
            baseURL: apiUrl,
            url: 'check-token',
        })
            .then((response) => response)
            .catch((error) => {
                console.log(error);
            });
    },

    getLocations() {
        return Axios({
            method: 'GET',
            baseURL: apiUrl,
            url: 'locations',
        }).then((response) =>
            checkAuthStatus(response)
        ).catch((error) => {
            console.log(error);
        });
    },

    getProfile(userId) {
        return Axios({
            method: 'GET',
            baseURL: apiUrl,
            url: 'profile',
            params: {
                userId: userId,
            }
        }).then((response) =>
            checkAuthStatus(response)
        ).catch((error) => {
            console.log(error);
        });
    },

    saveProfile(newProfile) {
        return Axios({
            method: 'POST',
            baseURL: apiUrl,
            url: 'profile',
            data: {
                userId: newProfile.userId,
                userFirstName: newProfile.firstName,
                userLastName: newProfile.lastName,
                userEmail: newProfile.email
            }
        }).then((response) =>
            checkAuthStatus(response)
        ).catch((error) => {
            console.log(error);
        });
    },
};